import React from "react";

const Instagram = () => {
  return (
    <path
      fillRule="evenodd"
      d="M27.328125,0 C29.90625,0 32,2.09375 32,4.671875 L32,27.328125 C32,29.90625 29.90625,32 27.328125,32 L4.671875,32 C2.09375,32 0,29.90625 0,27.328125 L0,4.671875 C0,2.09375 2.09375,0 4.671875,0 L27.328125,0 Z M28.671875,8 L28.671875,4 C28.671875,3.625 28.375,3.328125 28,3.328125 L24,3.328125 C23.625,3.328125 23.328125,3.625 23.328125,4 L23.328125,8 C23.328125,8.375 23.625,8.671875 24,8.671875 L28,8.671875 C28.375,8.671875 28.671875,8.375 28.671875,8 Z M16,10 C12.6875,10 10,12.6875 10,16 C10,19.3125 12.6875,22 16,22 C19.3125,22 22,19.3125 22,16 C22,12.6875 19.3125,10 16,10 Z M28.671875,28 L28.671875,14 L25.125,14 C25.265625,14.640625 25.328125,15.3125 25.328125,16 C25.328125,21.15625 21.15625,25.328125 16,25.328125 C10.84375,25.328125 6.671875,21.15625 6.671875,16 C6.671875,15.3125 6.734375,14.640625 6.875,14 L3.328125,14 L3.328125,28 C3.328125,28.375 3.625,28.671875 4,28.671875 L28,28.671875 C28.375,28.671875 28.671875,28.375 28.671875,28 Z"
    />
  );
};

export default Instagram;
