import React from "react";

const Image = () => {
  return (
    <path
      fillRule="evenodd"
      d="M5.33333333,2.5 L26.6666667,2.5 C29.6121853,2.5 32,4.88781467 32,7.83333333 L32,24.1666667 C32,27.1121853 29.6121853,29.5 26.6666667,29.5 L5.33333333,29.5 C2.38781467,29.5 3.60722001e-16,27.1121853 0,24.1666667 L0,7.83333333 C-3.60722001e-16,4.88781467 2.38781467,2.5 5.33333333,2.5 Z M3.55555556,24.1 L19.5555556,24.1 L11.5555556,13.3 L3.55555556,24.1 Z M23.1111111,18.7 C26.0566298,18.7 28.4444444,16.2823376 28.4444444,13.3 C28.4444444,10.3176624 26.0566298,7.9 23.1111111,7.9 C20.1655924,7.9 17.7777778,10.3176624 17.7777778,13.3 C17.7777778,16.2823376 20.1655924,18.7 23.1111111,18.7 Z"
    />
  );
};

export default Image;
