import styled from "styled-components";
import { space, color, fontSize } from "styled-system";
import { lighten } from "polished";
import { Link } from "gatsby";

import theme from "config/theme";

export const Wrapper = styled(Link)`
  line-height: 1;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  transition: ${theme.transition};
  color: ${theme.color.primary};
  font-size: ${theme.font.size.zeta};

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${lighten(0.03, theme.color.primary)};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${space};
  ${color};
  ${fontSize};
`;
