import React from "react";

const Cross = () => {
  return (
    <path
      fillRule="evenodd"
      d="M16,12.1969114 L27.4092659,0.787645446 C28.4594598,-0.262548482 30.1621606,-0.262548482 31.2123546,0.787645446 C32.2625485,1.83783938 32.2625485,3.54054016 31.2123546,4.59073408 L19.8030886,16 L31.2123546,27.4092659 C32.2625485,28.4594598 32.2625485,30.1621606 31.2123546,31.2123546 C30.1621606,32.2625485 28.4594598,32.2625485 27.4092659,31.2123546 L16,19.8030886 L4.59073408,31.2123546 C3.54054016,32.2625485 1.83783938,32.2625485 0.787645446,31.2123546 C-0.262548482,30.1621606 -0.262548482,28.4594598 0.787645446,27.4092659 L12.1969114,16 L0.787645446,4.59073408 C-0.262548482,3.54054016 -0.262548482,1.83783938 0.787645446,0.787645446 C1.83783938,-0.262548482 3.54054016,-0.262548482 4.59073408,0.787645446 L16,12.1969114 Z"
    />
  );
};

export default Cross;
