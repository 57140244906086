import React from "react";

const Github = () => {
  return (
    <path
      fillRule="evenodd"
      d="M16.0001325,0.5 C7.16466156,0.5 0,7.6149092 0,16.3922886 C0,23.4140342 4.58452494,29.3712409 10.9418671,31.4726853 C11.7415028,31.6197996 12.0350735,31.1279274 12.0350735,30.7081649 C12.0350735,30.3291943 12.020236,29.0772756 12.0133471,27.7492995 C7.5620948,28.7106742 6.62282757,25.8741848 6.62282757,25.8741848 C5.89499483,24.0372302 4.84630097,23.5487793 4.84630097,23.5487793 C3.39460981,22.5624031 4.95572759,22.5826675 4.95572759,22.5826675 C6.56241772,22.6945164 7.40842062,24.2203993 7.40842062,24.2203993 C8.83547092,26.6500217 11.1514469,25.9476103 12.0644835,25.5415328 C12.2080894,24.5146279 12.6227448,23.813006 13.0803229,23.4164027 C9.52647485,23.014536 5.79060236,21.651821 5.79060236,15.5622378 C5.79060236,13.8271316 6.41563237,12.4094132 7.43915545,11.2964503 C7.27302836,10.8958995 6.72536535,9.27974794 7.59415442,7.09066658 C7.59415442,7.09066658 8.93774374,6.66353517 11.9953302,8.71971366 C13.2716208,8.36758685 14.6403809,8.19099709 16.0001325,8.18494409 C17.3598841,8.19099709 18.729704,8.36758685 20.0083792,8.71971366 C23.0622563,6.66353517 24.4039909,7.09066658 24.4039909,7.09066658 C25.2748996,9.27974794 24.7269716,10.8958995 24.5608445,11.2964503 C25.5867522,12.4094132 26.207543,13.8271316 26.207543,15.5622378 C26.207543,21.6662956 22.4645167,23.0103252 18.9016601,23.4037704 C19.4755537,23.8969585 19.9869178,24.8641229 19.9869178,26.3468453 C19.9869178,28.473028 19.9683709,30.1844486 19.9683709,30.7081649 C19.9683709,31.1310855 20.2563776,31.6266421 21.0674063,31.47058 C27.4213041,29.366767 32,23.4116656 32,16.3922886 C32,7.6149092 24.8363983,0.5 16.0001325,0.5"
    />
  );
};

export default Github;
