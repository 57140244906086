import styled from "styled-components";
import { darken, lighten } from "polished";
import styledMap from "styled-map";

import theme from "config/theme";

import { Text, H6 } from "components/Text";
import Grid, { Container } from "components/Grid";
import { ButtonSimple } from "components/Button";

export const NavButton = styled(ButtonSimple)`
  display: table;
  margin-top: 22px;
  font-size: ${theme.font.size.epsilon};

  @media (max-width: ${theme.size.tablet}) {
    margin-top: 15px;
  }
`;

export const Wrapper = styled.footer`
  z-index: 2;
  position: relative;

  background-color: ${styledMap("shade", {
    dark: darken(0.01, theme.color.gray),
    default: theme.color.paper2
  })};

  ${NavButton} {
    color: ${styledMap("shade", {
      dark: theme.color.paper3,
      default: theme.color.gray3
    })};

    &:hover {
      color: ${theme.color.primary};
    }
  }

  ${H6} {
    color: ${styledMap("shade", {
      dark: "white",
      default: theme.color.gray
    })};
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 60px;
  left: 0;
  height: 30px;
  width: auto;

  @media (max-width: ${theme.size.tablet}) {
    position: relative;
    top: 0;
    margin: 40px 0 20px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  padding: 40px 0 0 240px;

  @media (max-width: ${theme.size.tablet}) {
    padding: 20px 0 0;
  }
`;

export const Nav = styled.div`
  padding: 20px 0;

  @media (min-width: ${theme.size.tablet}) {
    width: 27%;
  }

  @media (max-width: ${theme.size.tablet}) {
    width: auto;
    padding: 0 120px 30px 0;
  }

  @media (max-width: ${theme.size.mobile}) {
    width: 100%;
  }

  &:nth-child(1) {
    @media (min-width: ${theme.size.tablet}) {
      width: 35%;
      padding-right: 60px;
    }
  }

  &:last-child {
    // prettier-ignore
    @media (max-width: ${theme.size.desktop})
    and (min-width: ${theme.size.tablet}) {
      button {
        display: none;
      }
    }

    @media (min-width: ${theme.size.tablet}) {
      width: 38%;
      padding-left: 60px;
      border-left: 1px solid;

      border-color: ${styledMap("shade", {
        dark: lighten(0.01, theme.color.gray),
        default: darken(0.04, theme.color.paper2)
      })};
    }

    @media (max-width: ${theme.size.tablet}) {
      padding-right: 0;
    }
  }
`;

export const Legal = styled(Container)`
  padding: 30px 0;

  @media (min-width: ${theme.size.tablet}) {
    margin-top: 30px;
  }
`;

export const LegalInner = styled(Grid)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    margin-left: 15px;
    font-size: ${theme.font.size.epsilon};
    color: ${theme.color.gray4};

    @media (max-width: ${theme.size.tablet}) {
      margin: 0 15px 0 0;
    }

    &:hover {
      opacity: 1;
      color: ${theme.color.primary};
    }
  }
`;

export const Copyright = styled(Text)`
  line-height: 1.3;
  color: ${theme.color.gray3};
  font-size: ${theme.font.size.epsilon};

  @media (max-width: ${theme.size.tablet}) {
    width: 100%;
    order: 2;
    margin-top: 20px;
  }
`;
