import React from "react";

const Telegram = () => {
  return (
    <path
      fillRule="evenodd"
      d="M15.99997,0 C7.16344895,0 0,7.16338899 0,15.99997 C0,24.8365511 7.16344895,32 15.99997,32 C24.8365511,32 32,24.8365511 32,15.99997 C32,7.16338899 24.8365511,0 15.99997,0 Z M6.39930456,16.5578806 C6.39930456,16.5578806 5.77982363,16.3380976 5.71987193,15.858544 C5.65986028,15.3789303 6.41926848,15.1190397 6.41926848,15.1190397 L22.4663605,8.82411108 C22.4663605,8.82411108 23.7853579,8.24461793 23.7853579,9.20384515 L20.9275802,23.6122174 C20.9275802,23.6122174 20.5278822,24.6114324 19.4287877,24.1318188 L15.3622038,21.0140905 L15.3626834,21.0144502 L12.7541849,23.3926743 C12.7541849,23.3926743 12.5502892,23.5474096 12.3272089,23.4504078 L12.8541244,18.9559486 C12.8541244,18.9559486 20.168232,12.3812853 20.4679905,12.1015507 C20.767749,11.8216961 20.6678095,11.7617444 20.6678095,11.7617444 C20.6877734,11.4220581 20.1282442,11.7617444 20.1282442,11.7617444 L10.4360325,17.9168657 L6.39930456,16.5578806 Z"
    />
  );
};

export default Telegram;
