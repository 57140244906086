import React from "react";

const Alpha = () => {
  return (
    <path
      fillRule="evenodd"
      d="M21.5544041,24.399287 C20.4121992,26.8859304 19.0489429,28.6910223 17.4645941,29.8146168 C15.8802454,30.9382112 13.9366839,31.5 11.6338515,31.5 C7.7282476,31.5 4.81290542,30.2336728 2.88773748,27.7009804 C0.962569534,25.168288 0,21.3140485 0,16.1381462 C0,10.9069851 1.04547026,6.99288286 3.13644214,4.39572193 C5.22741402,1.79856099 8.29934646,0.5 12.3523316,0.5 C14.7657,0.5 16.8428238,1.11244189 18.5837651,2.33734403 C20.3247065,3.56224617 21.6373013,5.29826565 22.5215889,7.54545455 L22.6044905,7.54545455 C23.065057,5.26142649 23.7558964,3.09715817 24.6770294,1.05258467 L32,1.05258467 C31.078867,2.72876655 30.1255087,5.0403893 29.1398964,7.98752228 C28.1542841,10.9346553 27.550951,13.4673097 27.3298791,15.5855615 C27.9194042,21.2772121 29.1721263,26.3977788 31.0880829,30.9474153 L23.626943,30.9474153 C22.7242327,28.7370656 22.0702381,26.554378 21.6649396,24.399287 L21.5544041,24.399287 Z M8.15198618,16.0552585 C8.15198618,19.6654964 8.54346183,22.2534087 9.32642487,23.8190731 C10.1093879,25.3847375 11.385138,26.1675579 13.1537133,26.1675579 C14.79333,26.1675579 16.2026423,25.237383 17.3816926,23.3770053 C18.5607428,21.5166277 19.3160605,19.0484408 19.6476684,15.9723708 C19.2423699,12.6936853 18.5008689,10.1886599 17.4231434,8.45721925 C16.3454178,6.72577863 14.9959782,5.8600713 13.3747841,5.8600713 C11.4956728,5.8600713 10.1554444,6.67973037 9.35405872,8.31907308 C8.55267302,9.9584158 8.15198618,12.5371185 8.15198618,16.0552585 Z"
    />
  );
};

export default Alpha;
