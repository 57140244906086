import { createGlobalStyle } from "styled-components";
import { normalize, darken } from "polished";
import styledMap from "styled-map";

import theme from "./theme";
import {
  NeufileGroteskRegularWOFF2,
  NeufileGroteskRegularWOFF,
  NeufileGroteskBoldWOFF2,
  NeufileGroteskBoldWOFF
} from "./fonts";

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  @font-face {
    font-family: "neufile-grotesk";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      url(${NeufileGroteskRegularWOFF2}) format('woff2'),
  	  url(${NeufileGroteskRegularWOFF}) format('woff');
  }

  @font-face {
    font-family: "neufile-grotesk";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
      url(${NeufileGroteskBoldWOFF2}) format('woff2'),
  	  url(${NeufileGroteskBoldWOFF}) format('woff');
  }

  * {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: 'neufile-grotesk', sans-serif !important;
    font-display: swap;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  html,
  body {
    margin: 0;
    width: 100%;
    min-height: 100%;
  }

	body {
    line-height: 1.6;
    -webkit-text-size-adjust: none;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: ${theme.font.size.delta};

    color: ${styledMap("shade", {
      dark: "white",
      default: theme.color.gray
    })};

    background-color: ${styledMap("shade", {
      dark: theme.color.gray,
      light: "white",
      default: theme.color.paper
    })};
	}

  a {
    text-decoration: none;
    transition: ${theme.transition};

    p & {
      color: ${theme.color.primary};

      &:hover {
        color: ${darken(0.1, theme.color.primary)};
      }
    }
  }

  strong {
    color: inherit;
    font-weight: ${theme.font.weight.bold};
  }

  p, li {
    margin-top: 0;
    margin-bottom: 15px;
  }
`;

export default GlobalStyle;
