const theme = {
  color: {
    primary: "#FF3333",

    red: "#FF3333",
    blue: "#0077FF",

    gray: "#1F2427",
    gray2: "#4B4E53",
    gray3: "#777B82",
    gray4: "#91949A",
    gray77: "#c4c4c4",
    gray87: "#dedede",
    gray94: "#f0f0f0",

    paper: "#F9F9F9",
    paper2: "#F0F0F0"
  },

  font: {
    size: {
      tera: "100px",
      giga: "72px",
      mega: "60px",
      alpha: "42px",
      beta: "36px",
      cesar: "30px",
      gamma: "28px",
      theta: "24px",
      upsilon: "20px",
      delta: "18px",
      epsilon: "16px",
      zeta: "14px",
      kappa: "12px"
    },
    weight: {
      regular: 400,
      bold: 600
    }
  },

  size: {
    maxWidth: "1400px",
    desktop: "65em",
    tablet: "52em",
    mobile: "40em"
  },

  gutter: "30px",
  radius: "3px",
  noradius: "0px",
  transition: "ease-in-out 0.2s"
};

export default theme;
