import React from "react";

import { Wrapper } from "./styles";

const ButtonSimple = ({ to, shade, size, children, ...props }) => {
  if (!to) {
    return (
      <Wrapper as="button" shade={shade} size={size} {...props}>
        {children}
      </Wrapper>
    );
  }

  if (
    to.startsWith("https://") ||
    to.startsWith("http://") ||
    to.startsWith("mailto:")
  ) {
    return (
      <Wrapper
        as="a"
        href={to}
        shade={shade}
        size={size}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </Wrapper>
    );
  }

  return (
    <Wrapper to={to} shade={shade} size={size} {...props}>
      {children}
    </Wrapper>
  );
};

export default ButtonSimple;
