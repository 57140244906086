import React from "react";

const Facebook = () => {
  return (
    <path
      fillRule="evenodd"
      d="M30,0 C31.09375,0 32,0.90625 32,2 L32,30 C32,31.09375 31.09375,32 30,32 L22,32 L22,19.5625 L26,19.5625 L26.671875,14.953125 L22,14.953125 L22,11.328125 C22,10.015625 22.671875,9.265625 24,9.265625 L27,9.265625 L27,5.109375 C27,5.109375 25.65625,4.890625 23.71875,4.890625 C19.328125,4.890625 17.109375,7.328125 17.109375,10.96875 L17.109375,14.953125 L13.109375,14.953125 L13.109375,19.5625 L17.109375,19.5625 L17.109375,32 L2,32 C0.90625,32 0,31.09375 0,30 L0,2 C0,0.90625 0.90625,0 2,0 L30,0 Z"
    />
  );
};

export default Facebook;
