import styled from "styled-components";
import styledMap from "styled-map";
import { transparentize } from "polished";
import { space } from "styled-system";

import theme from "config/theme";
import Grid from "components/Grid";
import { H1 } from "components/Text";
import StyledButton from "components/Button";
import StyledImage from "components/Image";

export const Wrapper = styled.div`
  padding-top: 0px;
  position: relative;
  overflow: hidden;

  background-color: ${styledMap("shade", {
    dark: theme.color.gray,
    paper: theme.color.paper2,
    light: theme.color.paper,
    default: "white"
  })};
`;

export const Inner = styled(Grid)`
  z-index: 2;
  position: relative;
  padding-top: 90px;
  padding-bottom: 90px;
  max-width: 900px;

  @media (max-width: ${theme.size.tablet}) {
    padding-top: 20px;
    padding-bottom: 50px;

    ${space};
  }

  @media (max-width: ${theme.size.mobile}) {
    padding-bottom: 40px;

    ${space};
  }

  ${space};
`;

export const Heading = styled(H1)`
  margin: 0 auto 20px;
  text-align: center;
  font-size: ${theme.font.size.mega};

  color: ${styledMap("shade", {
    dark: "white",
    default: theme.color.gray
  })};
`;

export const Subheading = styled.p`
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: ${theme.font.size.theta};
  font-weight: ${theme.font.weight.regular};

  color: ${styledMap("shade", {
    dark: transparentize(0.2, theme.color.paper),
    default: theme.color.gray2
  })};

  @media (max-width: ${theme.size.tablet}) {
    font-size: ${theme.font.size.delta};
  }
`;

export const Button = styled(StyledButton)`
  display: table;
  margin: 40px auto 0;

  @media (max-width: ${theme.size.tablet}) {
    margin: 30px auto 0;
  }
`;

export const Background = styled(StyledImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  top: 0;
  left: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      ${transparentize(0.7, theme.color.gray)} 40%,
      ${transparentize(0.1, theme.color.gray)} 100%
    );
  }
`;
