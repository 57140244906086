import styled from "styled-components";
import styledMap from "styled-map";
import { space } from "styled-system";
import { transparentize, darken } from "polished";
import { Link } from "gatsby";

import theme from "config/theme";

export const Wrapper = styled(Link)`
  line-height: 1;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: ${theme.transition};
  border-radius: ${theme.radius};
  font-weight: ${theme.font.weight.bold};

  box-shadow: ${styledMap("shade", {
    clear: "none",
    light: "none",
    default: `inset 0 1px 0 ${transparentize(
      0.7,
      "white"
    )}, 0 1px 3px 0 ${transparentize(0.8, theme.color.gray)}`
  })};

  padding: ${styledMap("size", {
    small: "12px 13px 13px",
    default: "15px 25px 16px"
  })};

  font-size: ${styledMap("size", {
    small: theme.font.size.kappa,
    default: "13px"
  })};

  background-color: ${styledMap("shade", {
    clear: "transparent",
    light: theme.color.paper2,
    primary: theme.color.primary,
    default: theme.color.blue
  })};

  color: ${styledMap("shade", {
    clear: theme.color.gray,
    light: theme.color.gray,
    default: "white"
  })};

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: ${styledMap("shade", {
      clear: "transparent",
      light: darken(0.05, theme.color.paper2),
      primary: darken(0.05, theme.color.primary),
      default: darken(0.05, theme.color.blue)
    })};

    box-shadow: ${styledMap("shade", {
      clear: "none",
      light: "none",
      default: `inset 0 1px 0 ${transparentize(
        0.7,
        "white"
      )}, 0 2px 6px 0 ${transparentize(0.75, theme.color.gray)}`
    })};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${space};
`;
