import styled from "styled-components";
import styledMap from "styled-map";
import { transparentize } from "polished";
import { space } from "styled-system";

import theme from "config/theme";

import Grid from "components/Grid";
import { H1 } from "components/Text";
import StyledButton from "components/Button";
import StyledImage from "components/Image";

export const Wrapper = styled.div`
  padding-top: 90px;

  background-color: ${styledMap("shade", {
    dark: theme.color.gray,
    paper: theme.color.paper2,
    light: theme.color.paper,
    default: "white"
  })};
`;

export const Inner = styled(Grid)`
  position: relative;
  padding-top: 100px;
  padding-bottom: 130px;
  padding-right: 550px;

  ${space};

  @media (max-width: ${theme.size.desktop}) {
    padding-right: 450px;

    ${space};
  }

  @media (max-width: 55em) {
    padding-top: 50px;
    padding-right: 350px;
    padding-bottom: 60px;

    ${space};
  }

  @media (max-width: ${theme.size.tablet}) {
    padding-right: 30px;

    ${space};
  }
`;

export const Heading = styled(H1)`
  z-index: 2;
  position: relative;
  margin: 0 auto 25px;

  color: ${styledMap("shade", {
    dark: "white",
    default: theme.color.gray
  })};

  @media (max-width: 55em) {
    font-size: ${theme.font.size.cesar};
  }

  @media (max-width: ${theme.size.tablet}) {
    text-align: center;
  }
`;

export const Subheading = styled.p`
  z-index: 2;
  margin: 0 auto;
  position: relative;
  font-size: ${theme.font.size.theta};
  font-weight: ${theme.font.weight.regular};

  color: ${styledMap("shade", {
    dark: transparentize(0.2, theme.color.paper),
    default: theme.color.gray2
  })};

  @media (max-width: 55em) {
    font-size: ${theme.font.size.delta};
  }

  @media (max-width: ${theme.size.tablet}) {
    text-align: center;
    font-size: ${theme.font.size.delta};
  }
`;

export const Button = styled(StyledButton)`
  margin-top: 60px;

  @media (max-width: ${theme.size.tablet}) {
    display: table;
    margin: 40px auto 0;
  }
`;

export const Image = styled(StyledImage)`
  position: absolute !important;
  top: calc(50% - 20px);
  right: 50px;
  width: 450px;
  height: auto;
  transform: translateY(-50%);

  @media (max-width: ${theme.size.desktop}) {
    width: 380px;
  }

  @media (max-width: 55em) {
    width: 300px;
  }

  @media (max-width: ${theme.size.tablet}) {
    position: relative !important;
    top: auto;
    right: 0px;
    width: 100%;
    max-width: 300px;
    display: table;
    margin: -60px auto 20px;
    transform: none;
  }
`;
