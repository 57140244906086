import styled from "styled-components";
import { lighten, transparentize } from "polished";
import styledMap from "styled-map";

import theme from "config/theme";

import Grid from "components/Grid";
import { ButtonSimple } from "components/Button";

export const NavButton = styled(ButtonSimple)`
  margin-left: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border-radius: 0;
  font-size: ${theme.font.size.theta};

  @media (max-width: ${theme.size.tablet}) {
    margin: 0;
    padding: 15px 20px;
    width: 100%;
    border-bottom: 0px solid ${theme.color.light};
  }
`;

export const Wrapper = styled.header`
  width: 100%;
  position: absolute;
  z-index: 10;

  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    background-color: ${styledMap("shade", {
      home: theme.color.gray,
      light: theme.color.light,
      image: theme.color.gray,
      default: theme.color.paper
    })};

    box-shadow: ${styledMap("shade", {
      light: `0 1px 4px 0 ${transparentize(
        0.9,
        "black"
      )}, 0 2px 20px 0 ${transparentize(0.95, "black")}`,
      image: `0 1px 4px 0 ${transparentize(
        0.9,
        "black"
      )}, 0 2px 20px 0 ${transparentize(0.95, "black")}`,
      default: `0 1px 4px 0 ${transparentize(
        0.95,
        theme.color.gray2
      )}, 0 2px 20px 0 ${transparentize(0.97, theme.color.gray2)}`
    })};
  }

  .headroom--unfixed {
    position: absolute;
    transform: translateY(0);
    box-shadow: none;
    background-color: transparent;
    transition: 0.2s ease-in-out;
  }

  .headroom--scrolled {
    transition: transform 0.4s ease-in-out;
  }

  .headroom--unpinned {
    position: fixed;
    transform: ${props => !props.isVisible && "translateY(-105%)"};
  }

  .headroom--pinned {
    position: fixed;
    transform: translateY(0);
  }

  ${NavButton} {
    color: ${styledMap("shade", {
      image: transparentize(0.2, "black"),
      red: theme.color.gray3,
      default: theme.color.gray3
    })};

    &:hover {
      @media (min-width: ${theme.size.tablet}) {
        color: ${styledMap("shade", {
          light: theme.color.red,
          image: theme.color.red,
          red: theme.color.red,
          default: theme.color.gray
        })};
      }

      @media (max-width: ${theme.size.tablet}) {
        background-color: ${lighten(0.8, theme.color.gray)};
      }
    }

    &.active {
      font-weight: ${theme.font.weight.bold};

      color: ${styledMap("shade", {
        light: theme.color.red,
        image: theme.color.red,
        red: theme.color.red,
        default: theme.color.gray
      })};

      @media (max-width: ${theme.size.tablet}) {
        color: ${theme.color.red};
      }
    }
  }
`;

export const Inner = styled(Grid)`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 17px;

  @media (max-width: ${theme.size.tablet}) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const Logo = styled.img`
  height: 45px;
  width: auto;
  display: block;
  transition: ${theme.transition};

  @media (min-width: ${theme.size.tablet}) {
    margin-bottom: 2px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  padding-top: 70px;
  padding-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
  align-items: center;
  transition: ${theme.transition};

  @media (max-width: ${theme.size.tablet}) {
    padding-top: 0;
    position: absolute;
    width: 100%;
    top: -2px;
    left: 0;
    flex-direction: column;
    border-bottom: 0px solid ${theme.color.primary};
    background-color: ${transparentize(0, theme.color.paper)};
    transform: ${props => (props.isVisible ? "none" : "translateY(-100%)")};
    box-shadow: ${props =>
      props.isVisible
        ? `0 0 15px ${transparentize(0.5, theme.color.gray)}`
        : "none"};
  }
`;

export const MenuHeader = styled.div`
  display: none;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  background-color: ${theme.color.paper};
  border-bottom: 1px solid ${lighten(0.2, theme.color.red)};

  @media (max-width: ${theme.size.tablet}) {
    display: block;
  }
`;

export const MenuButton = styled.button`
  display: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  width: 24px;
  z-index: 2;
  padding: 0;
  background-color: transparent;

  @media (max-width: ${theme.size.tablet}) {
    display: block;
  }

  span {
    margin-top: 5px;
    opacity: ${props => (props.isVisible ? "0" : "1")};
  }

  &::before,
  &::after,
  span {
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    transition: ${theme.transition};
    transform-origin: 2px;

    background-color: ${styledMap("shade", {
      light: theme.color.red,
      image: "white",
      default: theme.color.gray
    })};

    transform: ${styledMap("isVisible", {
      false: "none",
      true: "rotate(45deg)"
    })};

    @media (max-width: ${theme.size.tablet}) {
      background-color: ${styledMap("shade", {
        light: theme.color.red,
        image: "white",
        default: props => (props.isVisible ? theme.color.red : theme.color.gray)
      })};
    }
  }

  &::after {
    margin-top: 5px;

    transform: ${styledMap("isVisible", {
      false: "none",
      true: "rotate(-45deg)"
    })};
  }

  &:focus {
    outline: none;
  }
`;
