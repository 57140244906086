import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import GlobalStyle from "config/global";
import Header from "components/Header";
import Footer from "components/Footer";

import favicon32 from "./favicon-32x32.png";
import favicon16 from "./favicon-16x16.png";

import { Wrapper } from "./styles";

const Layout = ({ page, shade, headerShade, footerShade, children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteQuery {
          site {
            siteMetadata {
              title
              description
              headerLinks {
                name
                path
              }
              footerLinks {
                column1 {
                  name
                  links {
                    name
                    path
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet>
            <html lang="en" />
            <title>
              {page && page.page.title ? `${page.page.title} | ` : ""}
              {data.site.siteMetadata.title}
            </title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
            <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          </Helmet>
          <GlobalStyle shade={shade} />
          <Header
            shade={headerShade}
            nav={data.site.siteMetadata.headerLinks}
          />
          <Wrapper>{children}</Wrapper>
          <Footer
            shade={footerShade}
            nav={data.site.siteMetadata.footerLinks}
          />
        </>
      )}
    />
  );
};

export default Layout;
