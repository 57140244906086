import React from "react";

const Medium = () => {
  return (
    <path
      fillRule="evenodd"
      d="M0,0 L32,0 L32,32 L0,32 L0,0 Z M7.6363619,10.7020727 L7.6363619,20.2036208 C7.69483221,20.546377 7.58678352,20.8965048 7.34531351,21.1467523 L5.08717949,23.885847 L5.08717949,24.2470462 L11.490244,24.2470462 L11.490244,23.885847 L9.23210993,21.1467523 C8.98886891,20.896919 8.87412969,20.5490985 8.92098924,20.2036208 L8.92098924,11.9863369 L14.5412339,24.2470462 L15.1935838,24.2470462 L20.0209725,11.9863369 L20.0209725,21.7587845 C20.0209725,22.0196506 20.0209725,22.0698171 19.850358,22.2403835 L18.1141038,23.9259802 L18.1141038,24.2871795 L26.5444708,24.2871795 L26.5444708,23.9259802 L24.8684336,22.2805168 C24.7204798,22.1677378 24.6470873,21.9823777 24.6777467,21.7989177 L24.6777467,9.70877463 C24.6470873,9.52531466 24.7204798,9.33995449 24.8684336,9.22717557 L26.5846154,7.58171211 L26.5846154,7.22051282 L20.6432139,7.22051282 L16.4079581,17.7855921 L11.5906055,7.22051282 L5.35815557,7.22051282 L5.35815557,7.58171211 L7.36538581,9.99974072 C7.56243692,10.1773507 7.66307082,10.4381796 7.6363619,10.7020727 Z"
    />
  );
};

export default Medium;
