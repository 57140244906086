import React from "react";

import logoInk from "components/Header/logo-ink.svg";
import logoWhite from "components/Header/logo-white.svg";

import { H6 } from "components/Text";
import Icon from "components/Icon";
import Grid, { Container, Block } from "components/Grid";
import CookieBar from "components/CookieBar";

import {
  Wrapper,
  Inner,
  Logo,
  Nav,
  NavButton,
  Legal,
  LegalInner,
  Copyright
} from "./styles";

const Footer = ({ shade, nav }) => {
  const logo = shade === "dark" ? logoWhite : logoInk;
  const year = new Date().getFullYear();

  return (
    <>
      <Wrapper shade={shade} aria-label="Footer">
        <Grid>
          <Container>
            <a href="https://clearmatics.com" title="Logo">
              <Logo src={logo} alt="Clearmatics" />
            </a>
            <Inner>
              <Nav>
                <H6>{nav.column1.name}</H6>
                {nav.column1.links.map((link, index) => (
                  <NavButton key={index} to={link.path}>
                    {link.name}
                  </NavButton>
                ))}
              </Nav>
              <Nav shade={shade}>
                <H6>Contact</H6>
                <NavButton
                  as="a"
                  href="https://goo.gl/maps/rRhy8DoW6HiVBScs8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Clearmatics Technologies Limited,
                  <br /> 30 Moorgate,
                  <br /> London, EC2R 6DA,
                  <br /> United Kingdom
                </NavButton>
                <NavButton as="a" href="mailto:ping@clearmatics.com">
                  General Enquiries
                </NavButton>
              </Nav>
            </Inner>
          </Container>
        </Grid>
        <Legal>
          <LegalInner>
            <Copyright>
              © {year} Clearmatics Technologies LTD - All rights reserved.
            </Copyright>
            <Block>
              <a
                href="https://gitter.im/clearmatics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="gitter" title="Clearmatics on Gitter" />
              </a>
              <a
                href="https://twitter.com/clearmatics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="twitter" title="Clearmatics on Twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/clearmatics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="linkedin" title="Clearmatics on LinkedIn" />
              </a>
              {/* <a
            href="https://instagram.com/clearmatics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="instagram" title="Clearmatics on Instagram" />
          </a> */}
              <a
                href="https://medium.com/clearmatics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="medium" title="Clearmatics on Medium" />
              </a>
              <a
                href="https://github.com/clearmatics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="github" title="Clearmatics on Github" />
              </a>
              <a
                href="https://facebook.com/clearmatics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="facebook" title="Clearmatics on Facebook" />
              </a>
            </Block>
          </LegalInner>
        </Legal>
      </Wrapper>
      <CookieBar />
    </>
  );
};

export default Footer;
