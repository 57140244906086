import Facebook from "./Facebook";
import Instagram from "./Instagram";
import Medium from "./Medium";
import Linkedin from "./Linkedin";
import Gitter from "./Gitter";
import Angel from "./Angel";
import Twitter from "./Twitter";
import Send from "./Send";
import Minus from "./Minus";
import Plus from "./Plus";
import Arrow from "./Arrow";
import Avatar from "./Avatar";
import Cross from "./Cross";
import Play from "./Play";
import Image from "./Image";
import Youtube from "./Youtube";
import Location from "./Location";
import Clock from "./Clock";
import Telegram from "./Telegram";
import Meetup from "./Meetup";
import Github from "./Github";
import Alpha from "./Alpha";

const Icons = {
  facebook: Facebook,
  instagram: Instagram,
  medium: Medium,
  linkedin: Linkedin,
  gitter: Gitter,
  angel: Angel,
  twitter: Twitter,
  send: Send,
  minus: Minus,
  plus: Plus,
  arrow: Arrow,
  avatar: Avatar,
  cross: Cross,
  play: Play,
  image: Image,
  youtube: Youtube,
  location: Location,
  clock: Clock,
  telegram: Telegram,
  meetup: Meetup,
  github: Github,
  alpha: Alpha
};

export default Icons;
