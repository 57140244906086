import React from "react";

const Linkedin = () => {
  return (
    <path
      fillRule="evenodd"
      d="M8,4.11795511 C8,6.14339152 6.4375,7.75137157 3.96875,7.75137157 L3.921875,7.75137157 C1.5625,7.75137157 0,6.14339152 0,4.11795511 C0,2.07705736 1.59375,0.5 4.03125,0.5 C6.4375,0.5 7.96875,2.07705736 8,4.11795511 Z M0.671875,11.0446384 L7.328125,11.0446384 L7.328125,31.5 L0.671875,31.5 L0.671875,11.0446384 Z M18,19.6256858 L18,31.5 L11.328125,31.5 L11.328125,15.667581 C11.328125,15.667581 11.21875,11.740399 11.21875,11.0446384 L17.78125,11.0446384 L18,13.8740648 C19.328125,11.8950125 21.328125,10.3952618 24,10.3952618 C28.671875,10.3952618 32,13.6885287 32,19.6256858 L32,31.5 L25.328125,31.5 L25.328125,20.2905237 C25.328125,16.9817955 23.703125,15.667581 21.703125,15.667581 C19.703125,15.667581 18,16.9817955 18,19.6256858 Z"
    />
  );
};

export default Linkedin;
