import React from "react";

const Youtube = () => {
  return (
    <path
      fillRule="evenodd"
      d="M31.3272727,8.0983871 C32,10.6580645 32,16 32,16 C32,16 32,21.3419355 31.3454545,23.9016129 C30.9636364,25.3298387 29.8909091,26.4427419 28.5090909,26.8137097 C26,27.5 16,27.5 16,27.5 C16,27.5 6,27.5 3.50909091,26.8137097 C2.12727273,26.4241935 1.03636364,25.3112903 0.672727273,23.9016129 C0,21.3419355 0,16 0,16 C0,16 0,10.6580645 0.654545455,8.0983871 C1.03636364,6.67016129 2.10909091,5.55725806 3.49090909,5.18629032 C6,4.5 16,4.5 16,4.5 C16,4.5 26,4.5 28.4909091,5.18629032 C29.8727273,5.57580645 30.9636364,6.68870968 31.3272727,8.0983871 Z M12.7272727,20.8596774 L21.0909091,16 L12.7272727,11.1403226 L12.7272727,20.8596774 Z"
    />
  );
};

export default Youtube;
