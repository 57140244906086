import React from "react";

import HeroFeature from "./HeroFeature";

import { Wrapper, Inner, Heading, Subheading, Image, Button } from "./styles";

const Hero = ({ content, shade, buttonShade, ...props }) => {
  return (
    <Wrapper shade={shade}>
      <Inner hasImage={content.image} {...props}>
        {content.image && <Image content={content.image} />}
        <Heading shade={shade}>{content.heading}</Heading>
        {content.subheading && (
          <Subheading shade={shade}>{content.subheading}</Subheading>
        )}
        {content.buttonPath && content.buttonText && (
          <Button shade={buttonShade || "primary"} to={content.buttonPath}>
            {content.buttonText}
          </Button>
        )}
      </Inner>
    </Wrapper>
  );
};

export default Hero;
export { HeroFeature };
