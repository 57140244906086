import React from "react";

import {
  Wrapper,
  Inner,
  Heading,
  Background,
  Subheading,
  Button
} from "./styles";

const HeroFeature = ({ content, shade, buttonShade, children, ...props }) => {
  return (
    <Wrapper shade={shade}>
      {content.background && <Background content={content.background} />}
      <Inner {...props}>
        <Heading shade={shade}>{content.heading}</Heading>
        {content.subheading && (
          <Subheading shade={shade}>{content.subheading}</Subheading>
        )}
        {children}
        {content.buttonPath && content.buttonText && (
          <Button shade={buttonShade} to={content.buttonPath}>
            {content.buttonText}
          </Button>
        )}
      </Inner>
    </Wrapper>
  );
};

export default HeroFeature;
