import React from "react";
import styled from "styled-components";
import CookieConsent from "react-cookie-consent";
import { darken, transparentize } from "polished";
import { Link } from "gatsby";

import theme from "config/theme";

const Wrapper = styled.div`
  .cookieConsent {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.1;
    padding: 5px 15px;
    align-items: center !important;
    color: ${theme.color.gray4} !important;
    background: white !important;
    box-shadow: 0 3px 25px 0 ${transparentize(0.78, theme.color.gray)};

    @media (max-width: 450px) {
      padding: 0 10px 10px;
    }

    & > div {
      margin: 15px 15px 15px 0 !important;
    }
  }

  a {
    color: ${theme.color.gray};

    &:hover {
      color: ${theme.color.primary};
    }
  }

  button {
    margin: 0 !important;
    cursor: pointer;
    font-size: 13px !important;
    padding: 13px 15px 14px !important;
    color: white !important;
    background: ${theme.color.primary} !important;
    transition: ${theme.transition};
    border-radius: ${theme.radius} !important;
    font-weight: ${theme.font.weight.bold};
    box-shadow: inset 0 1px 0 ${transparentize(0.7, "white")},
      0 1px 3px 0 ${transparentize(0.8, theme.color.gray)} !important;

    &:hover {
      background: ${darken(0.05, theme.color.primary)} !important;
    }
  }
`;

const CookieBar = () => {
  return (
    <Wrapper>
      <CookieConsent buttonText="Okay, thanks!">
        This website uses cookies,{" "}
        <Link to="/privacy-policy">find out more</Link>.
      </CookieConsent>
    </Wrapper>
  );
};

export default CookieBar;
