import React from "react";

const Location = () => {
  return (
    <path
      fillRule="evenodd"
      d="M2.5,13.7032372 C2.5,5.48129488 7.9,-8.75088752e-14 16,-8.8817842e-14 C24.1,-8.8817842e-14 29.5,6.8516186 29.5,13.7032372 C29.5,17.9575229 25.59643,23.7967432 17.78929,31.2208983 C16.7695776,32.190588 15.1811953,32.2304645 14.1141126,31.3131608 C6.37137085,24.6572557 2.5,18.7872812 2.5,13.7032372 Z M16,16.4438846 C18.4852814,16.4438846 20.5,14.3988352 20.5,11.8761389 C20.5,9.35344259 18.4852814,7.30839317 16,7.30839317 C13.5147186,7.30839317 11.5,9.35344259 11.5,11.8761389 C11.5,14.3988352 13.5147186,16.4438846 16,16.4438846 Z"
    />
  );
};

export default Location;
