import React from "react";

const Avatar = () => {
  return (
    <path
      fillRule="evenodd"
      d="M16,12.8 C12.4653776,12.8 9.6,9.9346224 9.6,6.4 C9.6,2.8653776 12.4653776,0 16,0 C19.5346224,0 22.4,2.8653776 22.4,6.4 C22.4,9.9346224 19.5346224,12.8 16,12.8 Z M0.00115854985,30.3984111 C0.690237377,20.8530317 7.57673306,16 15.9703846,16 C24.4821625,16 31.4753488,20.5863857 31.9962922,30.4 C32.0170452,30.7909482 31.9962922,32 30.6607999,32 L1.29333729,32 C0.847488276,32 -0.0363764453,30.9183599 0.00115854985,30.3984111 Z"
    />
  );
};

export default Avatar;
