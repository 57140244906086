import React from "react";

const Clock = () => {
  return (
    <path
      fillRule="evenodd"
      d="M16,32 C7.163444,32 -7.7172836e-14,24.836556 -7.89491929e-14,16 C-7.98373713e-14,7.163444 7.163444,1.77635684e-15 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z M15.8805507,5.33333333 C15.416074,5.33333333 15.0299038,5.69093896 14.9942801,6.15404756 L14.2248655,16.1564367 C14.1921649,16.5815442 14.465914,16.9699955 14.877254,17.0821792 L23.088733,19.3216734 C23.1469513,19.3375511 23.2070266,19.3455963 23.2673712,19.3455963 C23.6423337,19.3455963 23.9463008,19.0416292 23.9463008,18.6666667 L23.9463008,18.29362 C23.9463008,17.9746362 23.7753799,17.6801087 23.4984243,17.5218483 L17.7240786,14.2222222 L16.9152338,6.13377447 C16.8697936,5.67937287 16.4874243,5.33333333 16.0307563,5.33333333 L15.8805507,5.33333333 Z"
    />
  );
};

export default Clock;
