import React from "react";
import { Link } from "gatsby";
import Headroom from "react-headroom";

import Icon from "components/Icon";

import logoInk from "./logo-ink.svg";
import logoRed from "./clearmatics-research-red-logo.svg";

import {
  Wrapper,
  Inner,
  Logo,
  Nav,
  NavButton,
  MenuHeader,
  MenuButton
} from "./styles";

class Header extends React.Component {
  state = {
    isVisible: false
  };

  toggleVisiblity = () => {
    this.setState(state => ({ isVisible: !state.isVisible }));
  };

  render() {
    const { isVisible } = this.state;
    const { shade, nav } = this.props;

    const logo = shade === "light" || shade === "image" ? logoRed : logoInk;

    return (
      <Wrapper
        role="navigation"
        aria-label="main-navigation"
        shade={shade}
        isVisible={isVisible}
      >
        <Headroom disableInlineStyles>
          <Inner>
            <Link to="/" title="Logo">
              <Logo src={logo} alt="Clearmatics-Research" />
            </Link>
            <MenuButton
              shade={shade}
              onClick={this.toggleVisiblity}
              isVisible={isVisible}
            >
              <span />
            </MenuButton>
            <Nav isVisible={isVisible}>
              <MenuHeader>
                <Link to="/" title="Logo">
                  <Logo src={logo} alt="Clearmatics-Research" />
                </Link>
              </MenuHeader>
              {nav.map((link, index) => (
                <NavButton key={index} to={link.path} activeClassName="active">
                  {link.name}
                  {link.path.startsWith("https://medium.com/clearmatics") && (
                    <Icon icon="medium" size="12px" ml="5px" />
                  )}
                </NavButton>
              ))}
            </Nav>
          </Inner>
        </Headroom>
      </Wrapper>
    );
  }
}

export default Header;
