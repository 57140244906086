import React from "react";

const Play = () => {
  return (
    <path
      fillRule="evenodd"
      d="M8.87029881,31.7928261 C8.6723291,31.9278774 8.43924528,32 8.20075986,32 C7.5375985,32 7,31.4540217 7,30.7805232 L7,1.21959922 C7,0.976185839 7.07172665,0.738341971 7.20594884,0.53667774 C7.57732708,-0.0213053077 8.3237807,-0.16788558 8.87319965,0.209281538 L30.4715562,15.0361997 C30.5995889,15.1240921 30.709712,15.236258 30.7958821,15.366542 C31.1656582,15.9256214 31.0191538,16.6832801 30.4686553,17.0588202 L8.87029881,31.7928261 Z"
    />
  );
};

export default Play;
